import '../scss/Aboutme.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default function Aboutme() {
    return(
        <div className='aboutme' id='aboutme'>
            <div
                className='title'
                data-aos='fade-right'
                data-aos-duration="1500"
                data-aos-delay='0'
            >
                <h1>Szymon Smolorz<div className="material-symbols-outlined">waving_handwaving_hand</div></h1>
            </div>
                <div
                    className='description'
                    data-aos='fade-right'
                    data-aos-duration="1500"
                    data-aos-delay='300'
                >
                    <p>
                    As a passionate Frontend Developer, I create modern websites using React.
                    I’m 22, from Poland, and have been learning web development for over 6 years. My journey started in IT school with HTML and CSS,
                    but JavaScript and React truly sparked my interest.

                    I’m committed to building responsive, user-friendly websites and constantly expanding my knowledge. 
                    </p>
                </div>
            <div
                className='photo'
                data-aos='fade-left'
                data-aos-duration="1500"
                data-aos-delay='600'
            ></div>
        </div>
    )
}